body {
  font-family: 'Schoolbell', cursive;
}

html {
  scroll-behavior: smooth;
}

.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px;
}
.menu-item {
  padding: 20px;
}

.menu-item:hover {
  box-shadow: 0px 0px 5px #888888;
  cursor: pointer;
}

.menu-container > a, .menu-container > a::after {
  text-decoration: none;
  color: black;
}

.hero-container {
  position: relative;
  /* padding: 0 100px 0px 100px; */
}

.hero-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.hero-overlay {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: absolute;
}

.hero-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.hero-content-item {
  text-align: center;
}

.hero-title {
  color: #C3B1E1;
  font-size: 64px;
  font-family: 'Rock Salt', cursive;
  margin: 0;
  margin-top: 30px;
  line-height: 1.5;
  /* word-wrap: break-word; */
}

.hero-title-2 {
  color: #F8C8DC;
  font-size: 64px;
  font-family: 'Rock Salt', cursive;
  margin: 0;
  /* word-wrap: break-word; */
}

.about-title, .mission-title {
  color: white;
  font-size: 64px;
  font-family: 'Rock Salt', cursive;
  word-wrap: break-word;
}

.hero-sub-title {
  color: #F8C8DC;
  font-size: 64px;
  font-family: 'Rock Salt', cursive;
  margin: 0;
  word-wrap: break-word;
}

.featured-title {
  font-family: 'Rock Salt', cursive;
  font-size: 64px;
  word-wrap: break-word;
}

.button {
  min-height: 50px;
  min-width: 150px;
  border: 0;
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, #C3B1E1, #C3B1E1, #F8C8DC);
  color: white;
  font-size: 16px;
  font-family: 'Schoolbell', cursive;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  cursor: pointer;
  background-image: linear-gradient(to bottom right, #5c0273, #b5579f);
  opacity: 1; 
}

.svg-container {
  width: 100%;
  padding:0;
  margin:0;
  position: relative;
}

.about-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position:relative;
  background-image: linear-gradient(to bottom, #F8C8DC, lightpink);
}

.about-content-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  position:relative;
  padding: 0 50px 0 50px;
}

.about-text {
  color: white;
  font-size: 24px;
  text-align: justify;
  padding: 0 25px 0 25px;
}

.svg-wave-up {
  scale: 1;
  animation-name: wave-up;
  animation-duration: 2s;
}

.svg-wave-down {
  scale: 1;
  animation-name: wave-down, wave-up-2;
  animation-duration: 1s, 2s;
  animation-delay: 0ms, 1s;
  animation-fill-mode: forwards;
}

@keyframes wave-up {
  from {
    transform: scaleY(1.5);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes wave-up-2 {
  from {
    transform: scaleY(1.2);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes wave-down {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(1.2);
  }
}

.mission-title {
  color: white;
  font-size: 64px;
  word-wrap: break-word;
}

/*
  background-color: #C3B1E1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
*/
.mission-container {
  background-color: #C3B1E1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mission-container li {
  text-align: left;
}

.mission-text {
  color: white;
  font-size: 24px;
  text-align: justify;
}

.mission-item {
  padding: 0 50px 0 50px;
}

.featured-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: linear-gradient(to bottom, #C3B1E1, #F8C8DC);
  color: #3b3086;
}

.featured-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.featured-item {
  padding: 20px;
}

.featured-title-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}


.footer-container {
  display: flex;
  flex-direction: column;
  position:relative;
  color: white;
}

.footer-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #C3B1E1;
}

.footer-content-item {
  padding: 0 20px 0 20px;
}

.footer-title {
  color: white;
  font-family: 'Rock Salt', cursive;
  word-wrap: break-word;
  font-size: 64px;
}

.footer-divider {
  background-image: linear-gradient(to bottom, #F8C8DC,#C3B1E1);
}

.footer-email {
  font-size: 24px;
}

.footer-email a {
  font-size: 24px;
  color: white;
  text-decoration: none;
}

.footer-email a:visited {
  color: white;
  text-decoration: none;
}

.social {
  font-size: 32px;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {

  .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
    flex-wrap: wrap;
  }

  .hero-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .hero-content-item {
    text-align: center;
  }

  .logo {
    height: 250px;
  }

  .hero-donate {
    order: 2;
    padding: 5px;
  }

  .hero-img {
    order: 1;
  }
  
  .about-content-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    position:relative;
    text-align: center;
    padding: 0;
  }

  .mission-container {
    background-color: #C3B1E1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .mission-item {
    padding: 0 10px 0 10px;
  }

  .featured-item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }
  
  .hero-title, .hero-title-2, .about-title, .mission-title, .featured-title, .footer-title {
    font-size: 32px;
  }

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {

 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) and (max-width: 922px) {
  .hero-donate {
    padding: 5px;
  }

  .hero-title, .hero-title-2, .about-title, .mission-title, .featured-title, .footer-title {
    font-size: 48px;
  }

  .logo {
    height: 300px;
  }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {

 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

 }